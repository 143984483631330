import { AccountInfo, SilentRequest } from "@azure/msal-browser";
import { EnvironmentConfig, environmentData } from "../environments/env";
import { msalInstance} from "../index";

const validJWTToken = async (token: string): Promise<boolean> => {
  try {
    const decodedJwt = JSON.parse(window.atob(token.split(".")[1]));
    if (decodedJwt.exp * 1000 < Date.now()) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
};

const GetAccessToken = async (): Promise<string | null> => {
  let runEnv = environmentData["run"];
  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  let accessToken = "";
  let isValidaccessToken = await validJWTToken(
    sessionStorage.getItem("accessToken") || ""
  );
  if (!isValidaccessToken) {
    isValidaccessToken = await validJWTToken(
      localStorage.getItem("accessToken") || ""
    );
    sessionStorage.setItem(
      "accessToken",
      localStorage.getItem("accessToken") || ""
    );
  }
  if (!isValidaccessToken) {
    const loginCurrentRequest: SilentRequest = {
      scopes: [environmentConfig['api-scope']],
    };
    const activeAccount: AccountInfo | null = msalInstance.getActiveAccount();

    // Initialize page views count
    msalInstance.setActiveAccount(activeAccount);
    await msalInstance
      .acquireTokenSilent(loginCurrentRequest)
      .then((response) => {
        accessToken = response.accessToken;
        sessionStorage.setItem("accessToken", accessToken || "");
        localStorage.setItem("accessToken", accessToken || "");
      });
  }
  return sessionStorage.getItem("accessToken");
};

export { GetAccessToken };
