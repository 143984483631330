import React from "react";
import { GetRoles, GetUserName } from "../../services/AuthUser";
import AppHeader from "../../components/AppHeader";
import App from "../../App";
import errorimg from "../../assets/images/401ErrorPage.png";
interface LayoutProps {
  userDetails: any[];
}
const allowedRoles = ["admin", "user", "administrator"];
const Layout: React.FC<LayoutProps> = ({ userDetails }) => {
const userRole = GetRoles();

  return (
    <div className="wrapper d-flex flex-column min-vh-100">
      <AppHeader userDetails={GetUserName()} />
      <div className="layout">
        {userRole.some((role) => allowedRoles.includes(role.toLowerCase())) ? (
          <div data-testid='app-component'><App /></div>
        ) : (
          <div data-testid='unauthorized-message' id='unauthorizedMessage' className="unauthorizedmessage">
            <img src={errorimg} alt="401 error img" className="errorimg" />
            <h1> You are not authorized to access this application.</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Layout;
