import {
  NullableOption,
  SearchAggregation,
  SearchHit,
  SortProperty,
} from "@microsoft/microsoft-graph-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Fieldfilter } from "../common/interfaces";
import { SEARCH_CONFIG } from "../searchConfig";

export interface SearchDetails {
  results: SearchHit[];
  totalResults: number;
  searchText: string;
  refiners: SearchAggregation[];
  selectedRefiners: SearchAggregation[];
  sortProperty: NullableOption<SortProperty>;
  advanceSearchFilters: Fieldfilter[];
  pageNumber: number;
  pageSize: number;
  searchQuery: string;
  apiStatus: string;
}

const initialState: SearchDetails = {
  results: [],
  totalResults: 0,
  searchText: "",
  refiners: [],
  selectedRefiners: [],
  advanceSearchFilters: [],
  pageNumber: SEARCH_CONFIG.pageNumber,
  pageSize: SEARCH_CONFIG.pageSize,
  sortProperty: null,
  searchQuery: "",
  apiStatus: "",
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    updateSearch(state, action: PayloadAction<string>) {

      state.searchText = action.payload;
      state.refiners = [];
      state.totalResults = 0;
      state.results = [];
      state.selectedRefiners = [];
      state.pageNumber = SEARCH_CONFIG.pageNumber;
    },
    clearSearch(state) {
      state = initialState;
    },
    updateSearchResults(state, action: PayloadAction<SearchDetails>) {
      state.results = action.payload.results;
      state.refiners = action.payload.refiners;
      state.totalResults = action.payload.totalResults;
      state.searchQuery = action.payload.searchQuery;
    },
    updateRefiners(state, action: PayloadAction<SearchAggregation[]>) {
      state.selectedRefiners = action.payload;
    },
    updateApiStatus(state, action: PayloadAction<string>) {
      state.apiStatus = action.payload;
    },
    updateSortProperty(
      state,
      action: PayloadAction<NullableOption<SortProperty>>
    ) {
      state.sortProperty = action.payload;
    },
    updateAdvanceFilters(state, action: PayloadAction<Fieldfilter[]>) {

      state.advanceSearchFilters = action.payload;
    },
    updatePageNumber(state, action: PayloadAction<number>) {
      state.pageNumber = action.payload;
    },
    updatePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
  },
});

export const searchActions = searchSlice.actions;

export default searchSlice.reducer;
