export interface EnvironmentData {
  run: keyof typeof environmentData;
  development: EnvironmentConfig;
  staging: EnvironmentConfig;
  production: EnvironmentConfig;
}

export interface EnvironmentConfig {
  clientId: string;
  authority: string;
  tenantId: string;
  'api-scope': string;
  appInsightConnectionString: string;
  'function-app-url': string;
  'container-name': string;
  'storage-account-url': string;
  'sharepoint-list-url': string;
}
export const environmentData = {
  run: 'development',

  development: {
    clientId: 'f6b36f41-a2fa-4387-9028-bb24c0bcde6f',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid f6b36f41-a2fa-4387-9028-bb24c0bcde6f/user_impersonation',
    appInsightConnectionString:
      'InstrumentationKey=514eaa1c-389c-4bd0-9ae5-68a89872bdea;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
    'function-app-url':'https://vertexarchive.hmgroup.com:2443/api/GetToken',
    'container-name': 'vertex-archive-test',
    'storage-account-url': 'https://vertexarchive.hmgroup.com:1443',
    'sharepoint-list-url': 'https://hennesandmauritz.sharepoint.com/sites/vertex-archive-test/Lists/VertexArchive'
  },
  staging: {
    clientId: 'f6b36f41-a2fa-4387-9028-bb24c0bcde6f',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid f6b36f41-a2fa-4387-9028-bb24c0bcde6f/User.Impersonation',
    appInsightConnectionString:
      'InstrumentationKey=514eaa1c-389c-4bd0-9ae5-68a89872bdea;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
    'function-app-url':'https://vertexarchive.hmgroup.com:2443/api/GetToken',
    'container-name': 'vertex-archive-test',
    'storage-account-url': 'https://vertexarchive.hmgroup.com:1443',
    'sharepoint-list-url': 'https://hennesandmauritz.sharepoint.com/sites/vertex-archive-test/Lists/VertexArchive'
    }, 
  production: {
    clientId: 'f22c8ba3-9908-43e3-be98-8db35b6e7529',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid f22c8ba3-9908-43e3-be98-8db35b6e7529/user_impersonation',
    appInsightConnectionString:
      'InstrumentationKey=514eaa1c-389c-4bd0-9ae5-68a89872bdea;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
    'function-app-url':'https://vertexarchive.hmgroup.com:4443/api/GetToken',
    'container-name': 'vertex-archive-prod',
    'storage-account-url': 'https://vertexarchive.hmgroup.com:3443',
    'sharepoint-list-url': 'https://hennesandmauritz.sharepoint.com/sites/vertex-archive/Lists/VertexArchive',
  },
} as EnvironmentData
