import { environmentData, EnvironmentData, EnvironmentConfig } from '../environments/env';

let runEnv: keyof EnvironmentData = environmentData.run;

const environmentConfig: EnvironmentConfig = environmentData[runEnv] as EnvironmentConfig;

export const msalConfig = {
  auth: {
    clientId: environmentConfig.clientId,
    authority: environmentConfig.authority,
    tenantId: environmentConfig.tenantId,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
};
