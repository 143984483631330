import React from "react";
import {
  FormControl,
  Grid,
  Button,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  SearchFields,
  SearchFilterConditions,
  SearchFilterDateOperators,
  SearchFilterTextOperators,
  SearchFilterType,
} from "../../common/enum";
import { Fieldfilter } from "../../common/interfaces";
import {
  mapListFieldToSearchField,
  mapSearchDateOperators,
  mapSearchFieldToListField,
  mapSearchTextOperators,
} from "../../common/mapper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SEARCH_CONFIG } from "../../searchConfig";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

interface SearchFilterProps {
  index: number;
  filter: Fieldfilter;
  showAdditionButton: boolean;
  options: string[];
  showRemovalButton: boolean;
  onChange: (filter: Fieldfilter, index: number) => void;
  onAdditionButtonClick: (index: number) => void;
  onRemoveButtonClick: (index: number) => void;
}

const SearchFilter: React.FC<SearchFilterProps> = (props) => {
  const [optionsAvailable, setOptionsavailable] = React.useState<boolean>()
  const themes = createTheme({
    palette: {
      primary: {
        main: "#3C9A76",
      },
      secondary: {
        main: "#3C9A76",
      },
    },
  });

  const fieldNameChangeHandler = (
    event: SelectChangeEvent<typeof props.filter.fieldName>
  ) => {
    const fieldName = mapListFieldToSearchField(event.target.value);
    let fieldType = SearchFilterType.Text;

    if (SEARCH_CONFIG.dateFields.indexOf(fieldName) !== -1) {
      fieldType = SearchFilterType.Date;
    }

    const updatedFilter: Fieldfilter = {
      ...props.filter,
      fieldName: event.target.value,
      type: fieldType,
      fieldValue: "",
      operator: mapSearchTextOperators(
        SearchFilterTextOperators.equals.toString()
      ),
    };
    props.onChange(updatedFilter, props.index);
  };

  const fieldOperatorChangeHandler = (
    event: SelectChangeEvent<typeof props.filter.operator>
  ) => {
    const updatedFilter: Fieldfilter = {
      ...props.filter,
      operator: event.target.value,
    };
    props.onChange(updatedFilter, props.index);
  };

  const fieldDateValueChange = (value: Date | null) => {
    if (value !== null) {
      const selectedValue = dayjs(value).format("MM/YYYY");
      const updatedFilter: Fieldfilter = {
        ...props.filter,
        fieldValue: selectedValue,
      };
      props.onChange(updatedFilter, props.index);
    }
  };

  const fieldConditionChangeHandler = (
    event: SelectChangeEvent<typeof props.filter.condition>
  ) => {
    const updatedFilter: Fieldfilter = {
      ...props.filter,
      condition: event.target.value,
    };

    props.onChange(updatedFilter, props.index);
  };

  const additionClickHandler = () => {
    props.onAdditionButtonClick(props.index);
  };

  const removeClickHandler = () => {
    props.onRemoveButtonClick(props.index);
  };

  const mapSearchOperator = (operator: string): string => {
    if (props.filter.type === SearchFilterType.Date) {
      return mapSearchDateOperators(operator);
    } else {
      return mapSearchTextOperators(operator);
    }
  };
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ccc !important", // Set the desired border color on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "blue !important", // Border color when focused
            },
          },
          input: {
            padding: "8.5px 14px",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "rgba(0, 0, 0, 0.6) !important",
          },
          filled: {
            // Label color when focused (for filled variant)
            "&.Mui-focused": {
              color: "blue !important",
            },
          },
        },
      },
    },
  });
  return (
    <><Grid
      container
      marginY={2}
      justifyContent="space-between"
      direction={"row"}
      alignItems="center"
      className="headergridtop"
    >
      {props.index !== 0 ? (
        <React.Fragment>
          <Grid item xs={2} marginBottom={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel
                htmlFor={`search-filter-field-condition-${props.index}`}
              >
                Condition
              </InputLabel>
              <Select
                size="small"
                labelId={`search-filter-field-condition-${props.index}`}
                id={`search-filter-field-condition-${props.index}`}
                key={`search-filter-field-condition-${props.index}`}
                value={props.filter.condition}
                label="Condition"
                data-testid="Condition-conatiner"
                onChange={fieldConditionChangeHandler}
              >
                {Object.values(SearchFilterConditions).map(
                  (value, menuIndex) => (
                    <MenuItem
                      key={`search-filter-field-condition-${props.index}-${menuIndex}`}
                      value={value}
                    >
                      {value}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={10} className="itemgrid"></Grid>
        </React.Fragment>
      ) : (
        ""
      )}
      <Grid item xs={2} className="searchformgrid">
        <FormControl fullWidth variant="outlined">
          <InputLabel
            htmlFor={`search-filter-field-name-${props.index}`}
            className="custom-input-label"
            id={`fieldNameSelect-${props.index}`}
          >
            Field Name
          </InputLabel>
          <Select
            size="small"
            labelId={`search-filter-field-name-${props.index}`}
            id={`fieldNameSelect-${props.index}`}
            key={`search-filter-field-name-${props.index}`}
            value={props.filter.fieldName}
            label="Field Name"
            onChange={fieldNameChangeHandler}
          >
            {Object.values(SearchFields).map((field, menuIndex) => (
              <MenuItem
                value={mapSearchFieldToListField(field)}
                key={`search-filter-field-operator-${props.index}-${menuIndex}`}
              >
                {field}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2} paddingX={1}>
        <FormControl fullWidth variant="outlined">
          <InputLabel
            htmlFor={`search-filter-field-operator-${props.index}`}
            className="custom-input-label"
            id={`operatorSelect-${props.index}`}
          >
            Operator
          </InputLabel>
          <Select
            size="small"
            labelId={`search-filter-field-operator-${props.index}`}
            id={`operatorSelect-${props.index}`}
            key={`search-filter-field-operator-${props.index}`}
            value={props.filter.operator}
            label="Operator"
            onChange={fieldOperatorChangeHandler}
          >
            {Object.values(
              props.filter.type === SearchFilterType.Date
                ? SearchFilterDateOperators
                : SearchFilterTextOperators
            ).map((value, menuIndex) => (
              <MenuItem
                key={`search-filter-field-operator-${props.index}-${menuIndex}`}
                value={mapSearchOperator(value)}
                data-testid="operator-menu"
              >
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4} paddingX={1} style={{ textAlign: "left" }}>
        {props.filter.type === SearchFilterType.Date ? (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
              <DatePicker
                label="Date Value"
                value={new Date(props.filter.fieldValue)}
                format="MM/yyyy"
                views={["month", "year"]}
                key={`search-filter-date-${props.index}`}
                onChange={(newValue: Date | null) => fieldDateValueChange(newValue)} />
            </ThemeProvider>
          </LocalizationProvider>
        ) : (
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              size="small"
              id={`search-filter-field-value-${props.index}`}
              options={props.options}
              getOptionLabel={(option) => option || ""}
              value={props.filter.fieldValue || null}
              onChange={(event, newValue) => {
                setOptionsavailable(false);
                if (newValue) {
                  setOptionsavailable(true);
                  const selectedValue = newValue;
                  const updatedFilter: Fieldfilter = {
                    ...props.filter,
                    fieldValue: `${selectedValue}`,
                  };
                  props.onChange(updatedFilter, props.index);
                }
              } }
              renderInput={(params) => (
                <TextField {...params} label="String" variant="outlined" />
              )} />
          </FormControl>
        )}
      </Grid>
      <ThemeProvider theme={themes}>
        <Grid item xs={2} paddingX={1} className="d-flex">
          {props.showAdditionButton ? (
            <Button
              className="me-1"
              variant="contained"
              key={`search-filter-addition-button-${props.index}`}
              onClick={additionClickHandler}
              color="primary"
            >
              +
            </Button>
          ) : (
            ""
          )}
          {props.showRemovalButton ? (
            <Button
              variant="contained"
              key={`search-filter-remove-button-${props.index}`}
              onClick={removeClickHandler}
              color="primary"
            >
              -
            </Button>
          ) : (
            ""
          )}
        </Grid>
      </ThemeProvider>
    </Grid>{props.options.length === 0 && !optionsAvailable && (
      <div style={{ color: 'red' }}></div>
    )}</>
  );
};

export default SearchFilter;
