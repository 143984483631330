import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  NullableOption,
  SearchAggregation,
  SearchBucket,
  SortProperty,
} from "@microsoft/microsoft-graph-types";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import React, { useEffect, useState } from "react";
import {
  SearchFields,
  SearchListFields,
} from "../../common/enum";
import { searchActions } from "../../store/searchSlice";
import { mapSearchFieldToListField } from "../../common/mapper";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    success: {
      main: "#3C9A76",
    },
    secondary: {
      main: "#3C9A76",
    },
  },
});
const SearchRefiners = (props:any) => {
  const {fileStateRefiners,fileTypeRefiners,fileVersionRefiners, clearAllFilterRefiners,
    handleFileStateRefiners,handleFileVersionRefiners,handleFileTypeRefiners} = props
  const searchRefiners: SearchAggregation[] = useAppSelector(
    (state: any) => state.search.refiners
  );
  const searchAdvanceFilters = useAppSelector(
    (state: any) => state.search.advanceSearchFilters
  );
  const searchResultsCount: number = useAppSelector(
    (state: any) => state.search.totalResults
  );
  const searchText: string = useAppSelector(
    (state: any) => state.search.searchText
  );
  const sortProperty: SortProperty = useAppSelector(
    (state: any) => state.search.sortProperty
  );

  
  const [fileDateRefiners, setFileDateRefiners] = useState<SearchBucket[]>([]);
  const [sortBy, setSortBy] =
    useState<NullableOption<SortProperty>>(sortProperty);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setFileDateRefiners([]);
    setSortBy(null);
    clearAllFilterRefiners();
  }, [searchText]);

  const handleFileTypeRefinerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      // add to state
      handleFileTypeRefiners([
        ...fileTypeRefiners,
        { key: event.target.name, aggregationFilterToken: event.target.value },
      ]);
    } else {
      // remove from state
      handleFileTypeRefiners(
        fileTypeRefiners?.filter(
          (ft:any) => ft.key!.toLowerCase() !== event.target.name.toLowerCase()
        )
      );
    }
  };

  const handleFileStateRefinerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      // add to state
      handleFileStateRefiners([
        ...fileStateRefiners,
        { key: event.target.name, aggregationFilterToken: event.target.value },
      ]);
    } else {
      // remove from state
      handleFileStateRefiners(
        fileStateRefiners?.filter(
          (ft:any) => ft.key!.toLowerCase() !== event.target.name.toLowerCase()
        )
      );
    }
  };

  const handleFileVersionRefinerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      // add to state
      handleFileVersionRefiners([
        ...fileVersionRefiners,
        { key: event.target.name, aggregationFilterToken: event.target.value },
      ]);
    } else {
      // remove from state
      handleFileVersionRefiners(
        fileVersionRefiners?.filter(
          (ft:any) => ft.key!.toLowerCase() !== event.target.name.toLowerCase()
        )
      );
    }
  };

  const handleSortByChange = (event: SelectChangeEvent) => {
    setSortBy({ name: event.target.value , isDescending: false });
  };

  const handleFilterApplyClick = () => {
    const selectedRefiners: SearchAggregation[] = [];

    if (fileStateRefiners.length > 0) {
      selectedRefiners.push({
        field: SearchListFields.staFileState.toString(),
        buckets: fileStateRefiners,
      });
    }

    if (fileTypeRefiners.length > 0) {
      selectedRefiners.push({
        field: SearchListFields.staFileReportType.toString(),
        buckets: fileTypeRefiners,
      });
    }

    if (fileVersionRefiners.length > 0) {
      selectedRefiners.push({
        field: SearchListFields.staFileVersion.toString(),
        buckets: fileVersionRefiners,
      });
    }

    if (fileDateRefiners.length > 0) {
      selectedRefiners.push({
        field: SearchListFields.dtFileDate.toString(),
        buckets: fileDateRefiners,
      });
    }

    if (selectedRefiners.length > 0) {
      dispatch(searchActions.updateRefiners(selectedRefiners));
    }

    if (sortBy) {
      dispatch(searchActions.updateSortProperty(sortBy));
    }
  };

  const handleClearFilters = () => {
    clearAllFilterRefiners();
    setSortBy(null);
    dispatch(searchActions.updateRefiners([]));
    dispatch(searchActions.updateSortProperty(null));
  };

  const populateFileTypeRefiners = (refiner: SearchAggregation) => {
    if (refiner?.buckets && refiner?.buckets?.length > 0) {
      return (
        <FormControl fullWidth variant="standard">
          <FormGroup>
            {refiner?.buckets?.map((bucket: SearchBucket, index: number) => {
              const isChecked =
                fileTypeRefiners?.filter(
                  (fv:any) => fv.key!.toLowerCase() === bucket.key!.toLowerCase()
                ).length > 0;
              return (
                <FormControlLabel
                  key={`refiner_fileType_${index}`}
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleFileTypeRefinerChange}
                      name={bucket.key!}
                      data-testid="file-type-refiner"
                      value={bucket.aggregationFilterToken!}
                    />
                  }
                  label={`${bucket.key} (${bucket.count})`}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      );
    }
    return <Typography>No refiner available for File Type.</Typography>;
  };

  const populateFileStateRefiners = (refiner: SearchAggregation) => {
    if (refiner?.buckets && refiner?.buckets?.length > 0) {
      return (
        <FormControl fullWidth variant="standard">
          <FormGroup>
            {refiner?.buckets?.map((bucket: SearchBucket, index: number) => {
              const isChecked =
                fileStateRefiners?.filter(
                  (fv:any) => fv.key!.toLowerCase() === bucket.key!.toLowerCase()
                ).length > 0;

              return (
                <FormControlLabel
                  key={`refiner_fileState_${index}`}
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleFileStateRefinerChange}
                      name={bucket.key!}
                      data-testid='field-state-refiner'
                      value={bucket.aggregationFilterToken!}
                    />
                  }
                  label={`${bucket.key} (${bucket.count})`}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      );
    }
    return <Typography>No refiner available for File State.</Typography>;
  };

  const populateFileVersionsRefiners = (refiner: SearchAggregation) => {
    if (refiner?.buckets && refiner?.buckets?.length > 0) {
      return (
        <FormControl fullWidth variant="standard">
          <FormGroup>
            {refiner?.buckets?.map((bucket: SearchBucket, index: number) => {
              const isChecked =
                fileVersionRefiners?.filter(
                  (fv:any) => fv.key!.toLowerCase() === bucket.key!.toLowerCase()
                ).length > 0;

              return (
                <FormControlLabel
                  key={`refiner_fileVersion_${index}`}
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleFileVersionRefinerChange}
                      name={bucket.key!}
                      data-testid='file-version-refiner'
                      value={bucket.aggregationFilterToken!}
                    />
                  }
                  label={`${bucket.key} (${bucket.count})`}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      );
    }
    return <Typography>No refiner available for File Version.</Typography>;
  };

  return searchText ? (
    searchResultsCount > 0 && searchAdvanceFilters && searchAdvanceFilters.length > 0 ? (
      <aside>
        <Grid
          container
          justifyContent="space-between"
          direction={"row"}
          alignItems="center"
          className="AccordionGridHeader"
        >
          <Grid item xs={12} className="AccordionGridBody">
            <Typography
              variant="h6"
              component="h6"
              textAlign={"left"}
              marginY={2}
            >
              Filters
            </Typography>
          </Grid>
          <Grid item xs={6} className="AccordionGridSearch">
            <FormControl fullWidth size="small">
              <InputLabel id="refiner-sort-by-label">Sort By</InputLabel>
              <Select
                labelId="refiner-sort-by-label"
                id="refiner-sort-by"
                label="Sort By"
                onChange={handleSortByChange}
                data-testid='refiner-sort'
                value={sortBy ? sortBy.name : ""}
              >
                {Object.values(SearchFields).map((field, menuIndex) => (
                  <MenuItem
                    value={mapSearchFieldToListField(field)}
                    key={`refiner-sort-field-${menuIndex}`}
                  >
                    {field}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <ThemeProvider theme={theme}>
            <Grid item xs={4}>
              <Button
                variant="contained"
                size="small"
                data-testid={'apply-button'}
                onClick={handleFilterApplyClick}
                color="success"
              >
                Apply
              </Button>
            </Grid>
          </ThemeProvider>
        </Grid>
        <Grid
          container
          marginBottom={2}
          justifyContent="end"
          direction={"row"}
          alignItems="center"
        >
          <Grid item xs={4} alignSelf="end">
            <Button size="small" data-testid='clear-button' onClick={handleClearFilters}>
              CLEAR
            </Button>
          </Grid>
        </Grid>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>State</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {populateFileStateRefiners(
              searchRefiners?.filter(
                (refiner) => refiner.field === SearchListFields.staFileState
              )[0]
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Version</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {populateFileVersionsRefiners(
              searchRefiners?.filter(
                (refiner) => refiner.field === SearchListFields.staFileVersion
              )[0]
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Report Type</Typography>
          </AccordionSummary>
          <AccordionDetails data-testid='file-type-refiners'>
            {populateFileTypeRefiners(
              searchRefiners?.filter(
                (refiner) =>
                  refiner.field === SearchListFields.staFileReportType
              )[0]
            )}
          </AccordionDetails>
        </Accordion>
      </aside>
    ) : (
      <React.Fragment></React.Fragment>
    )
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default SearchRefiners;


