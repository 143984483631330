import React from "react";
import { CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CForm, CRow } from "@coreui/react";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../config/MsalConfig";
import appLogo from "../../assets/images/login-logo.svg";
import "../../assets/scss/style.scss";
import DefaultLayout from "../layout/Layout";

const Login: React.FC = () => {
    const { instance } = useMsal();
    /* istanbul ignore next */
    const handleLogin = (loginType: "popup" | "redirect"): void => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest);
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest);
        }
    };
    return (
        <div className="min-vh-100 d-flex flex-row align-items-center background" data-testid="login">
            
            <AuthenticatedTemplate data-testid="Logged" >
                <DefaultLayout userDetails={[]}  />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate data-testid="notLogged">
                <CContainer >
                    <CRow className="justify-content-center">
                        <CCol md={8}>
                            <CCardGroup>
                                <CCard className="text-white bg-login">
                                    <CCardBody className="text-center">
                                        <CCardBody>
                                            <img src={appLogo} alt="App Logo" className="app-logo pt-0" />
                                            <CForm className="pt-3">
                                                <h1 style={{ color: "#000000" }} key='vertexArchive'>Vertex Archive</h1>
                                                <p style={{ color: "#000000" }}>
                                                    Authenticate your account by logging into H & M Hennes & Mauritz GBC AB’s single sign-on (SSO) provider.
                                                </p>
                                                <CRow>
                                                    <CCol xs={12}>
                                                        <CButton
                                                            className="px-4 btn-login-button"
                                                            onClick={() => handleLogin("redirect")}
                                                            key="loginPopup"
                                                            data-testid="signInButton"
                                                        >
                                                            Sign In
                                                        </CButton>
                                                    </CCol>
                                                </CRow>
                                            </CForm>
                                        </CCardBody>
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>
                        </CCol>
                    </CRow>
                </CContainer>   
            </UnauthenticatedTemplate>
        </div>
    );
};

export default Login;
