import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";

import searchReducer from "./searchSlice";
import downloadReducer from "./downloadSlice";

const store = configureStore({
  reducer: {
    search: searchReducer,
    auth: authReducer,
    download: downloadReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
