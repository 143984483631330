/* istanbul ignore file */
import { Client, ResponseType } from "@microsoft/microsoft-graph-client";
import { searchActions } from "./searchSlice";
import {
  NullableOption,
  SearchAggregation,
  SearchRequest,
  SearchResponse,
  SortProperty,
} from "@microsoft/microsoft-graph-types";
import {
  SearchFilterConditions,
  SearchFilterType,
  SearchListFields,
} from "../common/enum";
import { Fieldfilter } from "../common/interfaces";
import { SEARCH_CONFIG } from "../searchConfig";
import {
  mapListFieldToSearchField,
  mapSearchListToField,
} from "../common/mapper";

const getGraphClient = (accessToken: string): Client | null => {
  if (accessToken) {
    // Initialize Graph client
    const graphClient = Client.init({
      // Use the provided access token to authenticate requests
      authProvider: (done) => {
        done(null, accessToken);
      },
    });

    return graphClient;
  }
  return null;
};

export const fetchSearchResponse = (
  accessToken: string,
  searchText: string,
  sortProperty: NullableOption<SortProperty>,
  searchRefiners: SearchAggregation[] = [],
  searchAdvanceFilters: Fieldfilter[] = [],
  pageNumber: number = SEARCH_CONFIG.pageNumber,
  pageSize: number = SEARCH_CONFIG.pageSize
) => {
  return async (dispatch: any) => {
    const buildFiltersQuery = (): string[] => {
      let output: string[] = [];

      if (searchRefiners && searchRefiners.length > 0) {
        output = searchRefiners
          .filter(
            (r) =>
                r.buckets &&
                r.buckets.length > 0 &&
                SEARCH_CONFIG.dateFields.indexOf(
                  mapListFieldToSearchField(r.field!)
                ) === -1
          )
          .map(
            (refiner: SearchAggregation) => 
            `${refiner.field}:${
                refiner.buckets!.length > 1
                  ? `or(${refiner.buckets!.map(
                      (b) => `${b.aggregationFilterToken}`
                    )})`
                  : `${refiner.buckets![0].aggregationFilterToken}`
              }`
          );
      }
      return output;
    };

    const buildSearchQuery = (): string => {
      let output: string = searchText;
      const dateSearchRefiners: SearchAggregation[] =
        searchRefiners && searchRefiners.length > 0
          ? searchRefiners.filter(
              (r) =>
                r.buckets &&
                r.buckets.length > 0 &&
                SEARCH_CONFIG.dateFields.indexOf(
                  mapListFieldToSearchField(r.field!)
                ) !== -1
            )
          : [];
        
      if (searchAdvanceFilters && searchAdvanceFilters.length > 0) {
        const advanceFilters: string[] = searchAdvanceFilters.map(
          (field: Fieldfilter) => {
            let operator: string = field.operator;
            let value: string = field.fieldValue;
            if (field.fieldName == "refinableDate11") {
              let parts = value.split('/');
              if (parts.length === 2) {
                  const dateValue = parts[1] + '/' + parts[0] + '/01';
                  const date = new Date(dateValue);
                  const daysToSubtract = 1;
                  date.setDate(date.getDate() - daysToSubtract);
                  const year = date.getFullYear();
                  const month = String(date.getMonth() + 1).padStart(2, "0");
                  const day = String(date.getDate()).padStart(2, "0");
                  const formattedDate = `${month}/${day}/${year}`;
                  value = formattedDate;
              }
          }
          // console.log(value, 'partvalue')
            if (field.type === SearchFilterType.Text) {
              let actualOperator: string = operator.replaceAll("*", "");
              // update value
              // add asterik based on operator
              if (operator.indexOf("*") !== -1) {
                value = operator.replace(actualOperator, value);
              }

              value = `"${value}"`;

              // remove asterik sign from operator
              operator = actualOperator;
            }

            return `${field.condition ? `${field.condition} ` : ""}${
              field.fieldName
            }${operator}${value}`;
          }
        );

        let advanceFilterQuery: string = "";

        advanceFilters.reverse();

        advanceFilters.forEach((filterQuery: string, index: number) => {
          if (advanceFilters.length >= 2) {
            if (index === 0) {
              advanceFilterQuery = `${advanceFilterQuery}`;
              advanceFilterQuery = `${filterQuery}${advanceFilterQuery}`;
            } else {
              filterQuery = filterQuery.replace(
                SearchFilterConditions.and.toString(),
                `${SearchFilterConditions.and.toString()} (`
              );
              filterQuery = filterQuery.replace(
                SearchFilterConditions.or.toString(),
                `${SearchFilterConditions.or.toString()} (`
              );

              advanceFilterQuery = `${filterQuery} ${advanceFilterQuery})`;
            }
          } else {
            advanceFilterQuery = `${filterQuery} ${advanceFilterQuery})`;
          }
        });
        output = `${output} AND (${advanceFilterQuery.trim()}`;
      }
      
      if (dateSearchRefiners.length > 0) {
        const dateSearchFields = dateSearchRefiners
          .map((r) => r.field!)
          .filter(
            (value: string, index: number, array: string[]) =>
              array.indexOf(value) === index
          ); // get distinct fields

        dateSearchFields.forEach((fieldName: string) => {
          const fieldRefiners = dateSearchRefiners.filter(
            (r) => r.field! === fieldName
          );
          output = `${output} AND ${fieldRefiners
            .map(
              (refiner) =>
                `(${refiner
                  .buckets!.map(
                    (bucket) =>
                      `${refiner.field!}${bucket.aggregationFilterToken}`
                  )
                  .join(" AND ")})`
            )
            .join(" ")}`;
        });
      }
      return output;
    };
  
    const buildSearchQueryForDisplay = (): string => {
      
      let output: string = searchText;
      const dateSearchRefiners: SearchAggregation[] =
        searchRefiners && searchRefiners.length > 0
          ? searchRefiners.filter(
              (r) =>
                r.buckets &&
                r.buckets.length > 0 &&
                SEARCH_CONFIG.dateFields.indexOf(
                  mapListFieldToSearchField(r.field!)
                ) !== -1
            )
          : [];
          if (searchRefiners && searchRefiners.length > 0) {
            const selectedRefiners = searchRefiners.map(
                      (refiner) => {
                        return `${mapListFieldToSearchField(refiner.field!)}= ${refiner.buckets!
                          .map((bucket) => `"${bucket.key}"`)
                          .join(" AND ")}`;
                      }).join(" AND ")
           output = `${output} AND (${selectedRefiners})`
                    }
           
      if (searchAdvanceFilters && searchAdvanceFilters.length > 0) {
        const advanceFilters: string[] = searchAdvanceFilters.map(
          (field: Fieldfilter) => {
            let operator: string = field.operator;
            let value: string = field.fieldValue;

            if (field.type === SearchFilterType.Text) {
              let actualOperator: string = operator.replaceAll("*", "");
              // update value
              // add asterik based on operator
              if (operator.indexOf("*") !== -1) {
                value = operator.replace(actualOperator, value);
              }

              value = `"${value}"`;

              // remove asterik sign from operator
              operator = actualOperator;
            }

            return `${
              field.condition ? `${field.condition} ` : ""
            }${mapSearchListToField(field.fieldName)}${operator}${value}`;
          }
        );

        let advanceFilterQuery: string = "";

        advanceFilters.reverse();
        advanceFilters.forEach((filterQuery: string, index: number) => {
          if (advanceFilters.length > 2) {
            if (index === 0) {
              advanceFilterQuery = `${advanceFilterQuery})`;
              advanceFilterQuery = `${filterQuery}${advanceFilterQuery}`;
            } else {
              filterQuery = filterQuery.replace(
                SearchFilterConditions.and.toString(),
                `${SearchFilterConditions.and.toString()} (`
              );
              filterQuery = filterQuery.replace(
                SearchFilterConditions.or.toString(),
                `${SearchFilterConditions.or.toString()} (`
              );
               advanceFilterQuery = `${filterQuery} ${advanceFilterQuery}`;
            }
          } else {
           advanceFilterQuery = `${filterQuery} ${advanceFilterQuery}`;
          }
        });
        
        output = `${output} AND (${advanceFilterQuery.trim()})`;
      }

      if (dateSearchRefiners.length > 0) {
        const dateSearchFields = dateSearchRefiners
          .map((r) => r.field!)
          .filter(
            (value: string, index: number, array: string[]) =>
              array.indexOf(value) === index
          ); // get distinct fields

        dateSearchFields.forEach((fieldName: string) => {
          const fieldRefiners = dateSearchRefiners.filter(
            (r) => r.field! === fieldName
          );
          output = `${output} AND ${fieldRefiners
            .map(
              (refiner) =>
                `(${refiner
                  .buckets!.map(
                    (bucket) =>
                      `${refiner.field!}${bucket.aggregationFilterToken}`
                  )
                  .join(" AND ")})`
            )
            .join(" ")}`;
        });
      }

      return output;
    };

    const buildSearchRequest = () => {
      const filterQuery = buildFiltersQuery();
     
      const searchQuery = buildSearchQuery();
   
      let request: SearchRequest = {
        entityTypes: ["listItem"],
        query: {
          queryString: `${searchQuery} path:"${SEARCH_CONFIG.listName}"`,
        },
        fields: Object.values(SearchListFields),
        from: pageSize * pageNumber,
        size: pageSize,
        aggregations: [
          SearchListFields.staFileReportType.toString(),
          SearchListFields.staFileState.toString(),
          SearchListFields.staFileVersion.toString(),
        ].map((fieldName) => {
          return {
            field: fieldName,
            size: 1000,
            bucketDefinition: {
              sortBy: "count",
              isDescending: true,
              minimumCount: 0,
            },
          };
        }),
        aggregationFilters: filterQuery,
        sortProperties: [sortProperty!],
      };

      if (filterQuery.length === 0) {
        delete request.aggregationFilters;
      }

      if (sortProperty === null) {
        delete request.sortProperties;
      }

      return {
        requests: [request],
      };
    };

    const fetchData = async () => {
      const graphClient = getGraphClient(accessToken);

      if (graphClient === null) return null;

      const data = await graphClient
        .api("/search/query")
        .header("Content-type", "application/json")
        .version("beta")
        .responseType(ResponseType.JSON)
        .post(buildSearchRequest());

      return data ? data.value[0] : null;
    };

    try {
      console.log("Try");
      dispatch(searchActions.updateApiStatus("Started"));
      const searchData: SearchResponse = await fetchData();
      console.log(searchData);
      console.log(searchData.hitsContainers!.length > 0);
      if (
        searchData &&
        searchData.hitsContainers &&
        searchData.hitsContainers.length > 0
      ) {
        dispatch(searchActions.updateApiStatus("Completed"));
        dispatch(
          searchActions.updateSearchResults({
            totalResults: searchData.hitsContainers[0].total as number,
            searchText: searchText,
            selectedRefiners: searchRefiners,
            refiners: searchData.hitsContainers[0].aggregations
              ? searchData.hitsContainers[0].aggregations
              : [],
            results: searchData.hitsContainers[0].hits
              ? searchData.hitsContainers[0].hits
              : [],
            advanceSearchFilters: searchAdvanceFilters,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sortProperty: sortProperty,
            searchQuery: buildSearchQueryForDisplay(),
            apiStatus: "Completed",
          })
        );
      }
    } catch (error) {
      console.error("Error", error);
      dispatch(searchActions.clearSearch());
    }
  };
};
