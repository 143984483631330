import {
  Checkbox,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { SearchListFields } from "../../common/enum";
import React from "react";
import { BlobStorageService } from "../../services/blobStorageService";
import { SEARCH_CONFIG } from "../../searchConfig";
import { CButton, CModal, CModalBody, CModalFooter} from '@coreui/react';
interface SearchResult {
  hitId?: string;
  rank: number;
  summary: string;
  resource: {
    "@odata.type": string;
    fields: {
      refinableString11: string;
      refinableString12: string;
      refinableString13: string;
      refinableString14: string;
      refinableString15: string;
      refinableString16: string;
      refinableString17: string;
      refinableString18: string;
      refinableDate11: string;
      refinableString19: string;
    };
  };
  isChecked: boolean; // Added property
}
interface SearchResultItemProps {
  details: SearchResult;
  isChecked: boolean;
  toggleCheckbox: (isChecked: boolean) => void;
  searchResultSelected: boolean[];
  index: number;
  updateprogressbar: any;
}
const SearchResultItem = (props: SearchResultItemProps) => {
  const { toggleCheckbox, searchResultSelected, index, updateprogressbar } =
    props;
  const [progress, setProgress] = React.useState(0);
  const [visible, setVisible] = React.useState(false)

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleCheckbox(event.target.checked);
    searchResultSelected?.forEach(() => event.target.checked);
    progressBarUpdate(0);
  };
  const fileLocationUrl: string =
    props.details.resource["fields"][SearchListFields.staFileLocationUrl];
  const [checked, setChecked] = React.useState([0]);
  const blobStorageService = new BlobStorageService(
    SEARCH_CONFIG.blobStorage.connectionString,
    SEARCH_CONFIG.blobStorage.containerName
  );

  const progressBarUpdate = (value: number) => {
    setProgress((prevProgress) => (prevProgress >= 100 ? 0 : value));
  };
  const downloadErrorFn = (message:string) => {
    setVisible(true)
  }
  const downloadClickHandler = (fileName: string) => () => {
    progressBarUpdate(0);
    const filePaths = [fileName];
    if (filePaths.length > 0) {
      try {
        blobStorageService.downloadMultipleFiles(filePaths, function () {
          progressBarUpdate(100);
        },downloadErrorFn);
      } catch (error) {
        //add catch logic
      }
    }
  };
  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  React.useEffect(() => {
    updateprogressbar(0);
  }, []);
  return (
    
    <>
        <Paper
          sx={{
            p: 0,
            flexGrow: 1,
          }}
          elevation={2}
        >
          <List className="SearchResultList">
            {[0].map((value) => {
              const labelId = `checkbox-list-label-${value}`;

              return (

                <ListItem
                  key={value}
                  disablePadding
                  className="SearchResultListItem"
                >
                  <ListItemButton
                    role={undefined}
                    data-testid="list-item-button"
                    onClick={handleToggle(value)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={searchResultSelected[index]}
                        onChange={handleCheckboxChange}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Link
                        variant="subtitle1"
                        id="downloadLink"
                        data-testid="download-link"
                        // target={"_blank"}
                        onClick={downloadClickHandler(fileLocationUrl)}
                      >
                        {props.details.resource["fields"][SearchListFields.staFileName]}
                      </Link>}
                      secondary={<>
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            ReportType:{" "}
                            {props.details.resource["fields"][SearchListFields.staFileReportType]}
                            , State:{" "}
                            {props.details.resource["fields"][SearchListFields.staFileState]}
                            , Version:{" "}
                            {props.details.resource["fields"][SearchListFields.staFileVersion]}
                            , Month/Year:{" "}
                            {props.details.resource["fields"][SearchListFields.staFileMonth]}
                            /
                            {props.details.resource["fields"][SearchListFields.staFileYear]}
                          </Typography>
                        </Grid>
                      </>} />
                    {/* <Grid
                container
                spacing={2}
                alignContent={"start"}
                textAlign={"left"}
              >
                <Grid item xs={12}>
                  <Link
                    variant="subtitle1"
                    target={"_blank"}
                    href={fileLocationUrl}
                  >
                    {
                      (props.details.resource! as any)["fields"][
                        SearchListFields.staFileName
                      ]
                    }
                  </Link>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2">
                    ReportType:{" "}
                    {
                      (props.details.resource! as any)["fields"][
                        SearchListFields.staFileReportType
                      ]
                    }
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2">
                    State:{" "}
                    {
                      (props.details.resource! as any)["fields"][
                        SearchListFields.staFileState
                      ]
                    }
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2">
                    Version:{" "}
                    {
                      (props.details.resource! as any)["fields"][
                        SearchListFields.staFileVersion
                      ]
                    }
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" gutterBottom>
                    Month/Year:{" "}
                    {
                      (props.details.resource! as any)["fields"][
                        SearchListFields.staFileMonth
                      ]
                    }
                    /
                    {
                      (props.details.resource! as any)["fields"][
                        SearchListFields.staFileYear
                      ]
                    }
                  </Typography>
                </Grid>
              </Grid> */}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Paper>
        <CModal
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="alertModalForDownloadMultiple"
        >
          <CModalBody>
            <p>Looks like a network restriction stops the download. Please contact application administrator for further assistance</p>
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" onClick={() => setVisible(false)}>
              Close
            </CButton>
          </CModalFooter>
      </CModal>
    </>
  );
};

export default SearchResultItem;
