import React from "react";
import { render } from "react-dom"; // Import 'render' from react-dom
import { HashRouter, Routes, Route } from "react-router-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./config/MsalConfig";
import "./assets/scss/_custom.scss";
import NoPage from "./pages/NoPage";
import Login from "../src/pages/login/Login";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "react-redux";
import store from "./store";

export const msalInstance = new PublicClientApplication(msalConfig);
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    if (event.payload && "account" in event.payload) {
      const account = event.payload.account;
      if (account) {
        msalInstance.setActiveAccount(account);
      }
    }
  }
});

const rootElement = document.getElementById("root");

const app = (
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <HashRouter>
          <Routes>
            <Route path="/" element={<Login />}>
              <Route index element={<h1>Hello</h1>} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </HashRouter>
      </MsalProvider>
    </Provider>
  </React.StrictMode>
);

// Use 'render' from react-dom to render your app
if (rootElement) {
  render(app, rootElement);
} else {
  console.error("Root element not found in the DOM");
}
