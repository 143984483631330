import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Button,
  Link,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import SearchFilter from "./SearchFilter";
import { Fieldfilter } from "../../common/interfaces";
import {
  SearchFields,
  SearchFilterConditions,
  SearchFilterTextOperators,
  SearchFilterType,
} from "../../common/enum";
import { searchActions } from "../../store/searchSlice";
import {
  mapSearchFieldToListField,
  mapSearchTextOperators,
} from "../../common/mapper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  SearchAggregation,
  SearchBucket,
} from "@microsoft/microsoft-graph-types";

const theme = createTheme({
  palette: {
    success: {
      main: "#3C9A76",
    },
    secondary: {
      main: "#3C9A76",
    },
  },
});
const AdvanceSearch = (props: any) => {
  const { selectedRefiners,clearAllFilterRefiners } = props;
  const maxFieldFilters: number = 4;
  const initialFilterState = {
    fieldName: mapSearchFieldToListField(SearchFields.fileState.toString()),
    operator: mapSearchTextOperators(
      SearchFilterTextOperators.equals.toString()
    ),
    fieldValue: "",
    condition: "",
    type: SearchFilterType.Text,
  };
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showAccordion, setShowAccordion] = useState(false);
  const [fieldValues, setFieldValues] = useState<string[]>([]);
  const [stateDropdownValues, setStateDropdownValues] = useState<string[]>([]);
  const [versionDropdownValues, setVersionDropdownValues] = useState<string[]>(
    []
  );
  const [reportTypeDropdownValues, setReportTypeDropdownValues] = useState<
    string[]
  >([]);
  const searchRefiners: SearchAggregation[] = useAppSelector(
    (state: any) => state.search.refiners
  );
  const searchResultsCount: number = useAppSelector(
    (state: any) => state.search.totalResults
  );
  const searchAdvanceFilters = useAppSelector(
    (state: any) => state.search.advanceSearchFilters
  );
  const dispatch = useAppDispatch();
  const [selectedFieldFilter, setSelectedFieldFilter] = useState<Fieldfilter[]>(
    [initialFilterState]
  );

  const filterChangeHandler = (filter: Fieldfilter, index: number) => {
    dispatch(searchActions.updateRefiners([]));
    clearAllFilterRefiners();
    const updatedFilters = [...selectedFieldFilter];

    updatedFilters[index] = filter;
    setSelectedFieldFilter(updatedFilters);
  };

  const additionClickHandler = (index: number) => {
    if (selectedFieldFilter.length < maxFieldFilters) {
      const updatedFilters = [...selectedFieldFilter];
      const nextPos = index + 1;
      const newFilter = {
        ...initialFilterState,
        condition: SearchFilterConditions.or.toString(),
      };

      if (nextPos >= updatedFilters.length) {
        updatedFilters.push(newFilter);
      } else {
        updatedFilters.splice(index, 0, newFilter);
      }
      setSelectedFieldFilter(updatedFilters);
    }
  };

  const removeClickHandler = (index: number) => {
    if (selectedFieldFilter.length > 1) {
      const updatedFilters = [...selectedFieldFilter];

      updatedFilters.splice(index, 1);

      setSelectedFieldFilter(updatedFilters);
    }
  };
  const showAccordionFn = () => {
    if(searchResultsCount > 0 && searchAdvanceFilters && searchAdvanceFilters.length > 0) {
        return showAccordion
    }
    return searchResultsCount > 0 && searchAdvanceFilters && searchAdvanceFilters.length === 0 ? !showAccordion : showAccordion;
  }

  const searchFilterOptions = (fieldName: string) => {
    if(fieldName === "refinableString17") {
      return reportTypeDropdownValues
    }
    return fieldName === "refinableString18" ? versionDropdownValues : fieldValues
  }
  const advanceSearchClickHandler = () => {
    const selectedRefiners: SearchAggregation[] = [];
    clearAllFilterRefiners();
    setShowProgressBar(true);
    setShowAccordion(false);
    dispatch(searchActions.updateSearch("*"));
    dispatch(searchActions.updateAdvanceFilters(selectedFieldFilter));
    dispatch(searchActions.updateRefiners(selectedRefiners));
    setTimeout(() => {
      setShowProgressBar(false);
    }, 1000);
  };
  const clearAdvanceSearchClickHandler = () => {
    setSelectedFieldFilter([initialFilterState]);
    dispatch(searchActions.updateAdvanceFilters([]));
    setShowAccordion(false);
  };
  const accordionOnclick = () => {
    if (searchResultsCount === 0 || searchResultsCount > 0) {
      setShowAccordion(!showAccordion);
    }
  };
  useEffect(() => {
    const updateDropdownValues = (refiner: SearchAggregation) => {
      if (refiner.buckets && refiner.buckets.length > 0) {
        const options = refiner.buckets.map(
          (bucket: SearchBucket) => `${bucket.key}`
        );
        setFieldValues(options);
        if (
          refiner.field === "refinableString13" &&
          stateDropdownValues.length <= 0
        ) {
          setStateDropdownValues(options);
        } else if (
          refiner.field === "refinableString17" &&
          reportTypeDropdownValues.length <= 0
        ) {
          setReportTypeDropdownValues(options);
        } else if (
          refiner.field === "refinableString18" &&
          versionDropdownValues.length <= 0
        ) {
          setVersionDropdownValues(options);
        }
      }
    };
 
    // Update dropdown values for all selectedFieldFilters
    selectedFieldFilter?.forEach((field) => {
      const filteredRefiners = searchRefiners?.filter(
        (refiner) => refiner.field === field.fieldName
      );
      if (filteredRefiners?.length > 0) {
        const refiner = filteredRefiners[0];
        updateDropdownValues(refiner);
      }
    });
  }, [searchRefiners]);

  
  return (
    <>
      <Accordion
        expanded={
          showAccordionFn()
        }
        className="Accordion1"
      >
        <AccordionSummary
          className="accordionheader"
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="advSearch-content"
          id="advSearch-header"
          onClick={accordionOnclick}
        >
          <Typography>Search (Click to show search options)</Typography>
        </AccordionSummary>
        <AccordionDetails className="AccordionDetails1">
          <Grid
            container
            justifyContent="space-between"
            direction={"row"}
            alignItems="center"
            className="AccordionDetailsGrid1"
          >
            <Grid item xs={10} className="accordionDetailsGrid2">
              {selectedFieldFilter.map((field: Fieldfilter, index: number) => (
                <>
                  <SearchFilter
                    index={index}
                    filter={field}
                    options={
                      field.fieldName === "refinableString13"
                        ? stateDropdownValues
                        : searchFilterOptions(field.fieldName)
                    }
                    showAdditionButton={
                      index < maxFieldFilters - 1 &&
                      index + 1 === selectedFieldFilter.length
                    }
                    showRemovalButton={index !== 0}
                    onChange={filterChangeHandler}
                    onAdditionButtonClick={additionClickHandler}
                    onRemoveButtonClick={removeClickHandler}
                    key={`search_filter_${index}`}
                  />
               </>
              ))}
            </Grid>
            <ThemeProvider theme={theme}>
              <Grid item xs={2} className="clearsearchgrid">
                <Link
                  component="button"
                  variant="body2"
                  onClick={clearAdvanceSearchClickHandler}
                  style={{textDecoration:'none'}}
                >
                  CLEAR
                </Link>
                <Button
                  onClick={advanceSearchClickHandler}
                  sx={{ marginX: 1 }}
                  variant="contained"
                  size="small"
                  startIcon={<ManageSearchIcon />}
                  color="success"
                >
                  Search
                </Button>
              </Grid>
            </ThemeProvider>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {showProgressBar && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "69vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default AdvanceSearch;
