import React from "react";
import {
  CNavItem,
  CNavLink,
  CContainer,
  CHeader,
  CHeaderNav,
  CAvatar,
} from "@coreui/react";

import appLogo from "../assets/images/hm-logo.png";

import { useMsal } from "@azure/msal-react";
import "../assets/scss/_headertitle.scss";
interface AppHeaderProps {
  userDetails: any[];
}

const AppHeader: React.FC<AppHeaderProps> = ({ userDetails }) => {
  const { instance } = useMsal();
  
  const handleLogout = (logoutType: string) => {
    if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
      sessionStorage.clear();
      localStorage.clear();
      instance.logoutRedirect();
    }
  };
  const getInitials = (user: string): string => {
    return user
      .split(" ")
      .map((initial) => initial[0])
      .join("")
      .replace(/[\])}[{(]/g, "");
  };

  return (
    <CHeader position="sticky" className="mb-1">
      <CContainer fluid>
        <CHeaderNav className="d-none d-md-flex">
          <CNavItem className="py-1 me-4">
            <img src={appLogo} alt="Logo" className="sidebar-icon" />
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="d-none d-md-flex mx-auto">
          <CNavItem>
            <div className="headertitle">Vertex Archive</div>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem></CNavItem>
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem className="d-flex align-items-center">
            <CAvatar
              className="profileAvatar"
              data-coreui-toggle="tooltip"
              data-coreui-placement="bottom"
              title={userDetails?.[0]?.userName}
              textColor="white"
              data-testid="userName"
              id='userName'
            >
              {userDetails.length > 0 &&
                userDetails?.[0]?.userName &&
                getInitials(userDetails?.[0]?.userName)}
            </CAvatar>
          </CNavItem>
          <CNavItem>
            <CNavLink
              onClick={() => handleLogout("redirect")}
              data-testid="logoutIcon"
              key='logoutIcon'
            >
              <CAvatar className="circleIcon">
                <span className="logoutSpriteIcon"></span>
              </CAvatar>
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
