import { msalInstance } from "../index";

const GetRoles = (): string[] => {
  let roles: string[] = [];
  const activeAccount = msalInstance.getActiveAccount();
  console.log(JSON.stringify(activeAccount),'Tocheckuserroles')
  if (activeAccount) {
    roles = activeAccount?.idTokenClaims?.roles ?? [];
    console.log(roles,'roles')
  }
  return roles;
};

const GetUserName = (): { userName: string; userEmail: string }[] => {
  let userDetails: { userName: string; userEmail: string }[] = [];
  let userName = sessionStorage.getItem("userSessionName");
  let userEmail = sessionStorage.getItem("userSessionEmail");

  if (!userName && !userEmail) {
    userName = localStorage.getItem("userSessionName");
    userEmail = localStorage.getItem("userSessionEmail");

    sessionStorage.setItem("userSessionName", String(userName));
    sessionStorage.setItem("userSessionEmail", String(userEmail));
  }

  if (userName === "null" || userEmail === "null") {
    const activeAccount = msalInstance.getActiveAccount();
    if (activeAccount) {
      const name = activeAccount.name ?? ""; // Ensure activeAccount.name is not undefined
      sessionStorage.setItem("userSessionName", name);
      localStorage.setItem("userSessionName", name);
      sessionStorage.setItem("userSessionEmail", activeAccount.username);
      localStorage.setItem("userSessionEmail", activeAccount.username);
      userName = name;
      userEmail = activeAccount.username;
    }
  }

  userDetails = [
    {
      userName: userName as string,
      userEmail: userEmail as string,
    },
  ];

  return userDetails;
};

export { GetUserName, GetRoles };
