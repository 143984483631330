import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import FileSaver from "file-saver";
import { SEARCH_CONFIG } from "../searchConfig";
import { GetAccessToken } from './AuthToken';
import { EnvironmentConfig, environmentData } from "../environments/env";
export class BlobStorageService {
  static mockImplementation(arg0: unknown) {
      throw new Error('Method not implemented.');
  }
  private _connectionString: string = "";
  private _containerName: string = "";

  constructor(connectionString: string, containerName: string) {
    if (connectionString && containerName) {
      this._connectionString = connectionString;
      this._containerName = containerName;
    }
  }

  private _getFilename(filePath: string): string {
    return filePath.substring(filePath.lastIndexOf("/") + 1);
  }
  public async getDownloadToken(ContainerName: string, BlobName: string) {
    // try {
      let runEnv = environmentData["run"];
      const environmentConfig: EnvironmentConfig = environmentData[
        runEnv
      ] as EnvironmentConfig;
      const apiUrl = environmentConfig['function-app-url'];
      const postdata = {
        ContainerName: ContainerName,
        BlobName: BlobName,
      };
      const accessTokenToBePassed: any =  await GetAccessToken()
      console.log(accessTokenToBePassed,'accessToken')
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenToBePassed}`
        },
        body: JSON.stringify(postdata),
      });

      if (response.ok) {
        const data = await response.text();
        // Handle the data received from the API
        return data;
      } else {
        // window.alert("Error downloading file. Please try again later.");
        // Handle the error case
        console.error("Request failed1", response.status);
        return "";
      }
  }
  
  
  public async downloadFileUsingToken(
    filePaths: string[],
    index: number,
    containerClient?: ContainerClient,
    callback?: any,
    errorCallback?:any,
  ) {
    let self = this;
    setTimeout(async function () {
      if (index <= filePaths.length - 1) {
        try {
          const filePath = filePaths[index];
          const container = SEARCH_CONFIG.blobStorage.containerName;
          const token = await self.getDownloadToken(container, filePath);
          if (token) {
            const apiUrl = `${SEARCH_CONFIG.blobStorage.url}/${container}/${filePath}?${token}`;
            const link = document.createElement("a");
            link.href = apiUrl;
            link.setAttribute("download", self._getFilename(filePath));
            console.log("Download started: ", filePath);
            const percentage = ((index + 1) / filePaths.length) * 100;
            callback(percentage);
            // Add a focus event listener to detect when the download starts
            // link.addEventListener("focus", () => {
            //   console.log("Download started: ", filePath);
            //   const percentage = index / (filePaths.length - 1) / 100;
            //   callback(percentage);
            //   // Here, you can update your UI or perform any necessary actions when the download starts.
            // });

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            index = index + 1;
            setTimeout(() => {
              self.downloadFileUsingToken(
                filePaths,
                index,
                containerClient,
                callback,
                errorCallback,
              );
            }, 5000);
          } else {
            console.log("Else");
          }
        } catch (error) {
          errorCallback(error)
          console.error("Request error2", error);
          return error;
        }
      }
    }, 200);
  }
 
  /**
     * downloadAsFile
        filePath: string     */
  public async downloadFile(
    filePath: string,
    containerClient?: ContainerClient
  ) {
    try {
      console.log("filepath 2", filePath, containerClient);
      if (containerClient == null) {
        const serviceClient = BlobServiceClient.fromConnectionString(
          this._connectionString
        );
        containerClient = serviceClient.getContainerClient(this._containerName);
      }

      if (filePath) {
        const blobClient = containerClient.getBlobClient(filePath);
        const downloadResponse = await blobClient.download();
        const blobContent = await downloadResponse.blobBody!;
        FileSaver.saveAs(blobContent, this._getFilename(filePath));
      }
    } catch (ex) {
      console.error(ex);
      throw ex;
    }
  }

  /**
     * downloadMultipleFiles
        filePaths: string[]     */
  public downloadMultipleFiles(filePaths: string[], callback: any, errorCallback:any) {
    if (filePaths && filePaths.length > 0) {
      const serviceClient = BlobServiceClient.fromConnectionString(
        this._connectionString
      );
      const containerClient = serviceClient.getContainerClient(
        this._containerName
      );
      this.downloadFileUsingToken(filePaths, 0, containerClient, callback,errorCallback);
    }
  }
}
