import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthDetails {
    isAuthenticated: boolean;
    accessToken: string | null;
    expiresOn: string | null;
}
 
const INITIAL_AUTH_STATE: AuthDetails = { isAuthenticated: false, accessToken: null, expiresOn: null };

const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_AUTH_STATE,
  reducers: {
    login(state, action: PayloadAction<AuthDetails>) {
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.expiresOn = action.payload.expiresOn;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.accessToken = null;
      state.expiresOn = null;
    },
  },
});

export default authSlice.reducer;
export const authActions = authSlice.actions;