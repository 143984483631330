import { createSlice } from "@reduxjs/toolkit";

const downloadSlice = createSlice({
  name: "isSelect",
  initialState: false, // initial state of the variable
  reducers: {
    setIsSelectAll: (state, action) => {
      return action.payload; // update the value of isSelect
    },
  },
});
export const { setIsSelectAll } = downloadSlice.actions;
export default downloadSlice.reducer;
