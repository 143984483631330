import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CircularProgressProps,
  Grid,
  Pagination,
  Snackbar,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { searchActions } from "../../store/searchSlice";
import SearchResultItem from "./SearchResultItem";
import { BlobStorageService } from "../../services/blobStorageService";
import { SEARCH_CONFIG } from "../../searchConfig";
import { SearchListFields } from "../../common/enum";
import React, { useState, useEffect, ChangeEvent } from "react";
import { CButton, CModal, CModalBody, CModalFooter} from '@coreui/react';
import {
  NullableOption,
  SearchAggregation,
  SearchHit,
  SortProperty,
} from "@microsoft/microsoft-graph-types";
import { Fieldfilter } from "../../common/interfaces";
interface SearchDetails {
  results: SearchHit[];
  totalResults: number;
  searchText: string;
  refiners: SearchAggregation[];
  selectedRefiners: SearchAggregation[];
  sortProperty: NullableOption<SortProperty>;
  advanceSearchFilters: Fieldfilter[];
  pageNumber: number;
  pageSize: number;
  searchQuery: string;
  apiStatus: string;
  isChecked: boolean;
}
interface SearchResultWithSelected {
  result: SearchResult;
  isChecked: boolean;
}
interface SearchResult {
  hitId: string;
  rank: number;
  summary: string;
  resource: {
    "@odata.type": string;
    fields: {
      refinableString11: string;
      refinableString12: string;
      refinableString13: string;
      refinableString14: string;
      refinableString15: string;
      refinableString16: string;
      refinableString17: string;
      refinableString18: string;
      refinableDate11: string;
      refinableString19: string;
    };
  };
  isChecked: boolean; // Added property
}
function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
const SearchResults = () => {
  const dispatch = useAppDispatch();
  const [progress, setProgress] = React.useState(0);

  const blobStorageService = new BlobStorageService(
    SEARCH_CONFIG.blobStorage.connectionString,
    SEARCH_CONFIG.blobStorage.containerName
  );
  const [open, setOpen] = React.useState(true);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [visible, setVisible] = useState(false)
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectAllChecked(event.target.checked);
    searchResultSelected?.forEach(() => event.target.checked);
    progressBarUpdate(0);
  };
  const searchResults: SearchResult[] = useAppSelector(
    (state: any) => state.search.results
  );
  const searchAdvanceFilters = useAppSelector(
    (state: any) => state.search.advanceSearchFilters
  );

  useEffect(() => {
    setSelectAllChecked(false);
  }, [searchResults]);

  const searchResultSelected: boolean[] = [];
  searchResults?.map((item) => searchResultSelected.push(selectAllChecked));

  const searchResultsCount: number = useAppSelector(
    (state: any) => state.search.totalResults
  );
  const apiStatus: string = useAppSelector(
    (state: any) => state.search.apiStatus
  );

  const pageSize: number = useAppSelector(
    (state: any) => state.search.pageSize
  );
  const pageNumber: number = useAppSelector(
    (state: any) => state.search.pageNumber
  );
  const advSearchQuery: any = useAppSelector((state: any) => {
    const searchQuery = state.search.searchQuery;
    if (searchQuery) {
      return searchQuery
        .replace(/^\* AND \(/, "")
        .replace(/\)$/, "")
        .replace(/[()]/g, "")
        .replace(/\*/g, "");
    }
    return "";
  });

  const totalPages: number = Math.ceil(searchResultsCount / pageSize);
  const pageStart: number = pageNumber * pageSize + 1;
  let pageEnd: number = pageStart + pageSize - 1;
  pageEnd = pageEnd > searchResultsCount ? searchResultsCount : pageEnd;

  const pageChangeHandler = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(searchActions.updatePageNumber(value - 1));
  };
  const progressBarUpdate = (value: number) => {
    setProgress((prevProgress) => (prevProgress >= 100 ? 0 : value));
  };
  const downloadErrorFn = (message:string) => {
    setVisible(true)
  }
  
  const downloadClickHandler = () => {
    progressBarUpdate(0);
    const filePaths = searchResults
      .filter((result, i) => searchResultSelected[i] === true)
      .map((result, i) => {
        const fileLocationUrl: string =
          result.resource["fields"][SearchListFields.staFileLocationUrl];
        return fileLocationUrl || "";
      })
      .filter((fp) => fp);
    if (filePaths.length > 0) {
      try {
        blobStorageService.downloadMultipleFiles(filePaths, progressBarUpdate, downloadErrorFn);
      } catch (error) {
        //add catch logic
      }
    }
  };
  return searchResultsCount > 0 &&
    searchAdvanceFilters &&
    searchAdvanceFilters.length > 0 ? (
    <>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item container xs={12}>
          <Grid item xs={1} className="selectAllCheckboxGrid">
            <Checkbox
              data-testid="select-all-checkbox"
              edge="start"
              checked={selectAllChecked}
              onChange={handleCheckboxChange}
              disableRipple
            />
          </Grid>
          <Grid item xs={8}>
            <Typography textAlign={"left"} marginTop={2} marginBottom={1}>
              Showing {pageStart}-{pageEnd} of {searchResultsCount}{" "}
              {advSearchQuery ? "for" : ""} {advSearchQuery}
            </Typography>
          </Grid>
          <Grid item xs={3} alignItems={"end"}>
            <Grid item container xs={12}>
              <Grid item xs={11}>
                <Button
                  data-testid="download-files"
                  color="inherit"
                  sx={{ marginTop: 2 }}
                  endIcon={<DownloadIcon />}
                  onClick={downloadClickHandler}
                >
                  Download Files
                </Button>
              </Grid>
              {progress > 0 && (
                <Grid item xs={1} sx={{ marginTop: 2 }}>
                  <CircularProgressWithLabel value={progress} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {searchResults.map((result: SearchResult, index: number) => (
          <>
            <Grid
              item
              xs={12}
              md={12}
              key={`searchResult_${index}`}
              className="SearchResultGrid"
            >
              <SearchResultItem
                key={`searchResultItem_${index}`}
                details={result}
                isChecked={searchResultSelected[index]}
                toggleCheckbox={(isChecked: boolean) => {
                  // Update the isChecked property of the result
                  // You can dispatch an action to update the store or modify the state directly
                  // For example, if using Redux:
                  // dispatch(updateSearchResultChecked(index, isChecked));

                  // If modifying the state directly:

                  searchResultSelected[index] = isChecked;

                  progressBarUpdate(0);
                }}
                searchResultSelected={searchResultSelected}
                index={index}
                updateprogressbar={progressBarUpdate}
              />
            </Grid>
          </>
        ))}
        {totalPages > 1 && (
          <Grid item container xs={12} data-testid='pagination'>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <Pagination
                count={totalPages}
                page={pageNumber + 1}
                color="primary"
                onChange={pageChangeHandler}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <CModal
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="alertModalForDownload"
        >
          <CModalBody>
            <p>Looks like a network restriction stops the download. Please contact application administrator for further assistance</p>
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" onClick={() => setVisible(false)}>
              Close
            </CButton>
          </CModalFooter>
      </CModal>
    </>
  ) : (
    <>
      {searchResultsCount === 0 && apiStatus === "Completed" && (
        <>
          <Typography variant="h6" component="h6" marginTop={2}>
            No Records Found
          </Typography>
          <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={(event, reason) => {
              // `reason === 'escapeKeyDown'` if `Escape` was pressed
              setOpen(false);
              // call `event.preventDefault` to only close one Snackbar at a time.
            }}
          >
            <Alert
              severity="error"
              sx={{
                width: "100%",
                backgroundColor: "#cc071e",
                color: "#ffffff",
              }}
            >
              No Records Found!
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
};

export default SearchResults;
