import {
  SearchFields,
  SearchFilterDateOperators,
  SearchFilterTextOperators,
  SearchListFields,
} from "./enum";

export const mapSearchFieldToListField = (field: string): string => {
  switch (field) {
    case SearchFields.fileState.toString():
      return SearchListFields.staFileState.toString();
    case SearchFields.fileVersion.toString():
      return SearchListFields.staFileVersion.toString();
    case SearchFields.fileType.toString():
      return SearchListFields.staFileReportType.toString();
    case SearchFields.fileDate.toString():
      return SearchListFields.dtFileDate.toString();
    default:
      return "";
  }
};
export const mapSearchListToField = (field: string): string => {
  switch (field) {
    case SearchListFields.staFileState.toString():
      return SearchFields.fileState.toString();
    case SearchListFields.staFileVersion.toString():
      return SearchFields.fileVersion.toString();
    case SearchListFields.staFileReportType.toString():
      return SearchFields.fileType.toString();
    case SearchListFields.dtFileDate.toString():
      return SearchFields.fileDate.toString();
    default:
      return "";
  }
};

export const mapListFieldToSearchField = (field: string): string => {
  switch (field) {
    case SearchListFields.staFileVersion.toString():
      return SearchFields.fileVersion.toString();
    case SearchListFields.staFileState.toString():
      return SearchFields.fileState.toString();
    case SearchListFields.dtFileDate.toString():
      return SearchFields.fileDate.toString();
    case SearchListFields.staFileReportType.toString():
      return SearchFields.fileType.toString();
    default:
      return "";
  }
};

export const mapSearchTextOperators = (operator: string): string => {
  switch (operator) {
    case SearchFilterTextOperators.equals.toString():
      return "=";
    case SearchFilterTextOperators.notEquals.toString():
      return "<>";
    // case SearchFilterTextOperators.contains.toString():
    //     return "*:*";
    // case SearchFilterTextOperators.notContains.toString():
    //     return "*<>*";
    case SearchFilterTextOperators.startsWith.toString():
      return ":*";
    // case SearchFilterTextOperators.endsWith.toString():
    //     return "*:";
    // case SearchFilterTextOperators.notStartsWith.toString():
    //     return "<>*";
    // case SearchFilterTextOperators.notEndsWith.toString():
    //     return "*<>";
    default:
      return "";
  }
};

export const mapSearchDateOperators = (operator: string): string => {
  switch (operator) {
    case SearchFilterDateOperators.equals.toString():
      return "=";
    case SearchFilterDateOperators.notEquals.toString():
      return "<>";
    case SearchFilterDateOperators.greaterThan.toString():
      return ">";
    case SearchFilterDateOperators.lessThan.toString():
      return "<";
    case SearchFilterDateOperators.greaterThanEquals.toString():
      return ">=";
    case SearchFilterDateOperators.lessThanEquals.toString():
      return "<=";
    default:
      return "";
  }
};
