import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "./hooks/storeHooks";
import "./App.css";
import { Box, Grid } from "@mui/material";
import { fetchSearchResponse } from "./store/searchActions";
import { AuthenticationResult } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { authActions } from "./store/authSlice";
import SearchRefiners from "./components/searchRefiners/SearchRefiners";
import SearchResults from "./components/searchResults/SearchResults";
import AdvanceSearch from "./components/advanceSearch/AdvanceSearch";
import { searchActions } from "./store/searchSlice";
import { SearchBucket } from "@microsoft/microsoft-graph-types";
const now = new Date();
function App() {
  const dispatch = useAppDispatch();
  const { instance } = useMsal();
  const searchText = useAppSelector((state: any) => state.search.searchText);
  const searchRefiners = useAppSelector(
    (state: any) => state.search.selectedRefiners
  );
  
  const searchAdvanceFilters = useAppSelector(
    (state: any) => state.search.advanceSearchFilters
  );
  const pageSize = useAppSelector((state: any) => state.search.pageSize);
  const pageNumber = useAppSelector((state: any) => state.search.pageNumber);
  const sortProperty = useAppSelector(
    (state: any) => state.search.sortProperty
  );
  const searchResultsCount: number = useAppSelector(
    (state: any) => state.search.totalResults
  );
  const accessToken = useAppSelector((state: any) => state.auth.accessToken);
  const expiresOn = useAppSelector((state: any) => state.auth.expiresOn);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      instance
        .acquireTokenSilent(loginRequest)
        .then((response: AuthenticationResult) => {
          dispatch(
            authActions.login({
              isAuthenticated: true,
              accessToken: response.accessToken,
              expiresOn: response.expiresOn!.toISOString(),
            })
          );
        });
    }
  }, [isAuthenticated, instance, dispatch]);
useEffect(() => {
     dispatch(searchActions.updateSearch("*"));
  }, [searchText]);
  useEffect(() => {
    if (
      searchText &&
      isAuthenticated &&
      accessToken &&
      expiresOn &&
      Date.parse(expiresOn) > Date.now()
    ) {
      dispatch(
        fetchSearchResponse(
          accessToken,
          searchText,
          sortProperty,
          searchRefiners,
          searchAdvanceFilters,
          pageNumber,
          pageSize
        )
      );
    }
  }, [
    accessToken,
    expiresOn,
    isAuthenticated,
    searchText,
    sortProperty,
    searchRefiners,
    searchAdvanceFilters,
    pageNumber,
    pageSize,
    dispatch,
  ]);
  const [fileTypeRefiners, setFileTypeRefiners] = useState<SearchBucket[]>([]);
  const [fileStateRefiners, setFileStateRefiners] = useState<SearchBucket[]>(
    []
  );
  const [fileVersionRefiners, setFileVersionRefiners] = useState<
    SearchBucket[]
  >([]);
  const handleFileStateRefiners = (value:any) => {
    setFileStateRefiners(value)
  }
  const handleFileVersionRefiners = (value:any) => {
    setFileVersionRefiners(value)
  }
  const handleFileTypeRefiners = (value:any) => {
    setFileTypeRefiners(value)
  }

  const clearAllFilterRefiners = () => {
    setFileTypeRefiners([])
    setFileStateRefiners([])
    setFileVersionRefiners([])
  }
  return (
    // isAuthenticatedState ?
    <Box sx={{ flexGrow: 1 }} className="App">
      <Grid container>
        <Grid container item spacing={1} padding={1}>
          <Grid item xs={2}>
            <SearchRefiners fileTypeRefiners={fileTypeRefiners} 
            fileStateRefiners={fileStateRefiners} 
            fileVersionRefiners={fileVersionRefiners} 
            clearAllFilterRefiners={clearAllFilterRefiners}
            handleFileStateRefiners={handleFileStateRefiners}
            handleFileVersionRefiners={handleFileVersionRefiners}
            handleFileTypeRefiners={handleFileTypeRefiners}/>
          </Grid>
          <Grid
            item
            xs={10}
            className={
              searchResultsCount > 0 && searchAdvanceFilters && searchAdvanceFilters.length > 0 ? "searchBoxContainer" : "mainContainer"
            }
          >
            <AdvanceSearch selectedRefiners={searchRefiners} clearAllFilterRefiners={clearAllFilterRefiners}/>
            {/* <AdvanceSearchTable items={data} /> */}
            <SearchResults />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default App;
