export enum SearchListFields {
    staFileName = 'refinableString11',
    staFileType = 'refinableString12',
    staFileState = 'refinableString13',
    staFileYear = 'refinableString14',
    staFileMonth = 'refinableString15',
    staFileDay = 'refinableString16',
    staFileReportType = 'refinableString17',
    staFileVersion = 'refinableString18',
    dtFileDate = 'refinableDate11',
    staFileLocationUrl = 'refinableString19'
}

export enum SearchFilterType {
    Text,
    Date
}

export enum SearchFilterTextOperators {
    equals = 'is equal',
    notEquals = 'not equal',
    //contains = 'contains',
    //notContains = 'not contains',
    startsWith = 'starts with',
    //notStartsWith = 'not starts with',
    //endsWith = 'ends with',
    //notEndsWith = 'not ends with'
}

export enum SearchFilterDateOperators {
    equals = 'is equal',
    notEquals = 'not equal',
    lessThan = 'less than',
    greaterThan = 'greater than',
    lessThanEquals = 'less than or equal',
    greaterThanEquals = 'greater than or equal'
}

export enum SearchFilterConditions {
    or = "OR",
    and = "AND"
}

export enum SearchFields {
    fileState = "State",
    fileVersion = "Version",
    fileType = "Report Type",
    fileDate = "Month/Year"
}

export enum DateRefiners {
    from,
    to
}