import { SearchFields } from "./common/enum";
import { EnvironmentConfig, environmentData } from "../src/environments/env";

let runEnv = environmentData["run"];
      const environmentConfig: EnvironmentConfig = environmentData[
        runEnv
      ] as EnvironmentConfig;
export const SEARCH_CONFIG = {
  pageNumber: 0,
  pageSize: 10,
  listName:
    environmentConfig['sharepoint-list-url'],
  dateFields: [SearchFields.fileDate.toString()],
  searchTitle: "Vertex Archive APP - POC",
  linkValueMaxLength: 20,
  blobStorage: {
    connectionString:
      "BlobEndpoint=https://stvertexarchivetest001.blob.core.windows.net/;QueueEndpoint=https://savertexarchivetest01.queue.core.windows.net/;FileEndpoint=https://savertexarchivetest01.file.core.windows.net/;TableEndpoint=https://savertexarchivetest01.table.core.windows.net/;SharedAccessSignature=sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-07-17T15:59:00Z&st=2023-07-17T07:59:00Z&spr=https,http&sig=oAA4hVIRYqFLzyfYNrQC6THdgiOiDLn5aElrmLKW9s0%3D",
    containerName: environmentConfig['container-name'],
    url: environmentConfig['storage-account-url'],
  },
};
